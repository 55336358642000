import React, {useState, useEffect, useRef} from "react";
import { v4 as uuidv4 } from 'uuid';
import { dbService, storageService } from "../firebase";
import {serverTimestamp} from "firebase/firestore";
import emailjs from '@emailjs/browser';
import Bg from "../assets/mains_bn.png";
import Mobg from "../assets/mo_bg.jpg";
import a from "../assets/01.jpg";
import b from "../assets/02.jpg";


function Door() {

  const form = useRef();

  
  const [page, setPage] = useState(0);
  let timeoutId;
  
  const [message, setMessage] = useState('');
  
  const [name, setName] = useState("");
  const [pnumber, setPnumber] = useState("");
  const [email, setEmail] = useState("");

  const [purchase, setPurchase] = useState("");
  const [lodging, setLodging] = useState("");

  const [targetitem, setTargetitem] = useState("");
  const [targetarea, setTargetarea] = useState("");

  const [targetareaboth, setTargetareaboth] = useState("");
  const [targetareafirst, setTargetareafirst] = useState("");
  const [targetareasecond, setTargetareasecond] = useState("");

  const [engname, setEngname] = useState("");
  const [unumber, setUnumber] = useState("");
  const [uexpire, setUexpire] = useState("");
  const [usernumber, setUsernumber] = useState("");
  const [incomeuser, setIncomeuser] = useState("");
  const [receipt, setReceipt] = useState("");

  const [taxreceipt, setTaxreceipt] = useState("");
  const [cashreceipt, setCashreceipt] = useState("");
  const [noreceipt, setNoreceipt] = useState("");

  const [businessnumber, setBusinessnumber] = useState("");
  const [cashnumber, setCashnumber] = useState("");
  const [note, setNote] = useState("");
  const [check, setCheck] = useState(false);
  
  const onSubmit = async (event) => {
      event.preventDefault();
      await dbService.collection("inquiryss").add({
          name: name,
          pnumber: pnumber,
          email: email,
          purchase: purchase,
          targetitem: targetitem,
          targetarea: targetarea,
          lodging: lodging,
          incomeuser: incomeuser,
          note: note,
          check: check,
          createdAt: Date.now(),
          join: serverTimestamp(),
      });
  
       
        const templateParams = {
          name: name,
          pnumber: pnumber,
          email: email,
          purchase: purchase,
          targetitem: targetitem,
          targetarea: targetarea,
          lodging: lodging,
          incomeuser: incomeuser,
          note:note,
          check: check,
      };

      emailjs.send('service_mlyzkhb', 'template_l9mkqlk', templateParams, 'tErS3089lQKLhaQ7_')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

  };





    useEffect(() => {
        return () => clearTimeout(timeoutId);
      }, []);


      const onCheckChange = (event) => {
        setCheck(event.target.checked);
      };

      //핸드폰
      const handlePhoneChange = (e) => {
        const phoneNum = e.target.value;
        setPnumber(phoneNum
          .replace(/[^0-9]/g, '')
          .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
          .replace(/(\-{1,2})$/g, "")
        );
      }

      const handleUexpireChange = (e) => {
        const uexpireNum = e.target.value;
        setUexpire(uexpireNum
          .replace(/[^0-9]/g, '')
          .replace(/^(\d{0,4})(\d{0,2})(\d{0,2})$/g, "$1-$2-$3")
          .replace(/(\-{1,2})$/g, "")
        );
      }

      const handleUsernumberChange = (e) => {
        const usernumberNum = e.target.value;
        setUsernumber(usernumberNum
          .replace(/[^0-9]/g, '')
          .replace(/^(\d{0,6})(\d{0,7})$/g, "$1-$2")
          .replace(/(\-{1})$/g, "")
        );
      }


//이메일
      const handleEmailChange = (event) => {
        setEmail(event.target.value);
        const regEmail = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
        if (regEmail.test(email)) {
          setMessage("yes");
        } else {
          setMessage("no");
        }
      };
    
//영문여권명
      const handleengnameChange = (event) => {
        setEngname(event.target.value);
        const newEngnameValue = event.target.value.toUpperCase();
        setEngname(newEngnameValue);
      };

    



      const Pages = () => {
        if (page === 0) {
          return(
            <div className='maincontents'>
                <div>
                    <div className='titlearea'>
                        <div className='littletitle'>신청인 이름</div><div style={{color:'red'}}>*</div>
                    </div>
                        <input
                            className="name"
                            type="text"
                            spellCheck="false"
                            value={name}
                            name="user_name"
                            onChange={(event) => {setName(event.target.value)}} />
                </div>


                <div>
                    <div className='titlearea'>
                        <div className='littletitle'>휴대폰 번호</div><div style={{color:'red'}}>*</div>
                    </div>
                         <input
                             className="pnumber"
                             type="text"
                             spellCheck="false"
                             value={pnumber}
                             name="user_pnumber"
                             maxLength={13}
                             onChange={handlePhoneChange} />
                </div>


                <div>
                    <div className='titleareas'>
                       <div className='littletitle'>신청인 이메일</div><div style={{color:'red'}}>*</div>
                    </div>
                    {message === 'no' ? <div className="warning">이메일을 확인해주세요</div> : ''}
                         <input
                             className="email"
                             type="text"
                             spellCheck="false"
                             name="user_email"
                             value={email}
                             onChange={handleEmailChange} />
                </div>


                <div>
                  <div className='titlearea'>
                    <div className='littletitle'>중달이닷컴 거래 여부</div><div style={{color:'red'}}>*</div>
                  </div>
                  <div style={{width:"80%", margin:"auto"}}>
                    <span className="tack">
                    <input
                      style={{width:"20px", cursor:"pointer"}}
                      type="radio"
                      name="purchase"
                      value="거래경험있음"
                      checked={purchase === "거래경험있음"}
                      onChange={() => setPurchase("거래경험있음")}
                    /> <p style={{fontSize:"0.9rem"}}>거래경험있음</p>
                    <input
                      style={{width:"20px", cursor:"pointer", marginLeft:"20px"}}
                      type="radio"
                      name="purchase"
                      value="거래경험없음"
                      checked={purchase === "거래경험없음"}
                      onChange={() => setPurchase("거래경험없음")}
                    /> <p style={{fontSize:"0.9rem"}}>거래경험없음</p>
                    </span>
                  </div>
                </div>
                

                <div>
                    <div className='titlearea'>
                      <div className='littletitle'>관심 아이템*</div>
                    </div>
                          <input
                              className="company"
                              type="text"
                              spellCheck="false"
                              name="user_targetitem"
                              value={targetitem}
                              onChange={(event) => {setTargetitem(event.target.value)}} />
                </div>

                <div>
                    <div className='titlearea'>
                      <div className='littletitle'>지역/일정</div><div style={{color:'red'}}>*</div>
                    </div>
                    {/*
                      <div style={{width:"80%", margin:"auto"}}>
                    <div className="tgarea" style={{
                      padding:"10px 10px", margin:"left", fontWeight:"bold",
                      borderRadius:"4px", textAlign:"left"}}>
                      110차 이우 시장조사단 8월8일 ~ 8월12일 <span style={{color:"red"}}> / 4박5일</span><br/><p style={{fontSize:"0.6rem", fontWeight:"normal"}}>*110차 시장조사단은 광저우 일정은 진행되지않습니다.</p>
                    </div>
                    </div>
                    */}
                      
                      <select
                             name="user_targetarea"
                             value={targetarea}
                             onChange={(event) => {setTargetarea(event.target.value)}} >
                                <option selected="selected">지역 및 일정을 선택해주세요.</option>
                                <option>이우 10월18일(금) ~ 22일(화)/4박5일</option>
                                <option>광저우 10월24일(목) ~ 28일(월)/4박5일</option>
                                <option>이우+광저우 10월18일(금) ~ 28일(월)/10박11일</option>
                         </select>
                      


                      {/*}
                  <label><input type="radio" name="user_targetarea" value={targetareaboth} onClick={(event) => {setTargetareaboth('이우/광저우(8박9일)')}}/></label><div className="surecheck" style={{marginRight:"8px"}}>이우/광저우(8박9일)</div>
                  <label><input type="radio" name="user_targetarea" value={targetareafirst} onClick={(event) => {setTargetareafirst('이우(4박5일)')}}/></label><div className="surecheck" style={{marginRight:"8px"}}>이우(4박5일)</div>
                  <label><input type="radio" name="user_targetarea" value={targetareasecond} onClick={(event) => {setTargetareasecond('광저우(4박5일)')}}/></label><div className="surecheck">광저우(4박5일)</div>

          */}


                  <div className="littletitlecheck">
                                  
                                          <br/>
                                          [금액 안내]<br/>
                                          <br/>
                                          이우 시장조사단<br/>

                                          총 금액 1,150,000원<br/>
                                          ( 조사단비용 770,000원 + 왕복항공료 380,000원, vat포함 )<br/><br/><br/>


                                          광저우 시장조사단<br/>

                                          총 금액 1,440,000원<br/>
                                          ( 조사단비용 990,000원 + 왕복항공료 450,000원, vat포함 )<br/><br/><br/>


                                          이우/광저우 시장조사단<br/>

                                          총 금액  2,390,000원<br/>
                                          ( 조사단비용  1,760,000원 + 왕복항공료  630,000원, vat포함 )<br/>


                  </div>
                </div>

          {/*
                <div>
                    <div className='titlearea'>
                      <div className='littletitle'>여권 영문 이름</div><div style={{color:'red'}}>*</div>
                    </div>
                    <div className='extra'>※여권과 동일해야합니다.</div>
                         <input
                             className="business"
                             type="text"
                             spellCheck="false"
                             name="user_engname"
                             value={engname}
                             onChange={handleengnameChange} />
                </div>

                <div>
                    <div className='titlearea'>
                      <div className='littletitle'>여권번호</div><div style={{color:'red'}}>*</div>
                    </div>
                         <input
                             className="business"
                             type="text"
                             spellCheck="false"
                             name="user_unumber"
                             value={unumber}
                             onChange={(event) => {setUnumber(event.target.value)}} />
                </div>

                <div>
                    <div className='titlearea'>
                      <div className='littletitle'>여권 만료일</div><div style={{color:'red'}}>*</div>
                    </div>
                         <input
                             className="business"
                             type="text"
                             placeholder="ex)2028-09-07"
                             spellCheck="false"
                             name="user_uexpire"
                             maxLength={10}
                             value={uexpire}
                             onChange={handleUexpireChange} />
                </div>

                <div>
                    <div className='titlearea'>
                      <div className='littletitle'>주민등록번호</div><div style={{color:'red'}}>*</div>
                    </div>
                         <input
                             className="business"
                             type="text"
                             spellCheck="false"
                             name="user_usernumber"
                             value={usernumber}
                             maxLength={14}
                             onChange={handleUsernumberChange} />
                </div>
        */}

                <div>
                  <div className='titlearea'>
                    <div className='littletitle'>숙소</div><div style={{color:'red'}}>*</div>
                  </div>
                  <div style={{width:"80%", margin:"auto"}}>
                    <span className="tack" style={{display:"block"}}>
                    <span style={{display:"flex"}}>
                    <input
                      style={{width:"20px", cursor:"pointer"}}
                      type="radio"
                      name="lodging"
                      value="기본(2인실)"
                      checked={lodging === "기본(2인실)"}
                      onChange={() => setLodging("기본(2인실)")}
                    /> <p style={{fontSize:"0.9rem"}}>기본(2인실)</p>
                    </span>
                    <span style={{display:"flex"}}>
                    <input
                      style={{width:"20px", cursor:"pointer"}}
                      type="radio"
                      name="lodging"
                      value="1인실"
                      checked={lodging === "이우1인실"}
                      onChange={() => setLodging("이우1인실")}
                    /> <p style={{fontSize:"0.9rem"}}>이우 1인실 추가 금액 (1박 48,000원)</p>
                    </span>
                    <span style={{display:"flex"}}>
                      <input
                      style={{width:"20px", cursor:"pointer"}}
                      type="radio"
                      name="lodging"
                      value="1인실"
                      checked={lodging === "광저우1인실"}
                      onChange={() => setLodging("광저우1인실")}
                    /> <p style={{fontSize:"0.9rem"}}>광저우 1인실 추가 금액 (1박 80,000원)</p>
                    </span>
                    </span>
                    {/*<div className="tacks" style={{color:"red", fontSize:"0.6rem", textAlign:"left"}}> *이우 1인실 추가 금액 : 1박 / 48,000원 (4박 / 총 192,00원)<br/>*광저우 1인실 추가 금액 : 1박 / 48,000원 (4박 / 총 192,00원)</div>*/}
                  </div>
                </div>

                <div>
                    <div className='titlearea'>
                      <div className='littletitle'>입금자명</div><div style={{color:'red'}}>*</div>
                    </div>
                    <div className='extra' style={{width:"300px"}}>※입금자명이 동일해야 확인이 가능합니다.<br/>현금영수증 = 개인 | 세금계산서 = 사업자명 으로 입금해주세요.</div>
                         <input
                             className="business"
                             type="text"
                             spellCheck="false"
                             name="user_incomeuser"
                             value={incomeuser}
                             onChange={(event) => {setIncomeuser(event.target.value)}} />
                </div>


{/*
                <div>
                    <div className='titlearea' style={{marginTop:"80px"}}>
                      <div className='littletitle'>영수증</div><div style={{color:'red'}}>*</div>
                    </div>
                    <div className='titleareasssarea'>
                    <div className="checking">
                      </div>

                      <select
                             name="user_receipt"
                             value={receipt}
                             onChange={(event) => {setReceipt(event.target.value)}} >
                                <option selected="selected">영수증을 선택해주세요.</option>
                                <option>세금계산서</option>
                                <option>현금영수증</option>
                                <option>필요없음</option>
                         </select>
      
                      
                      
                  <label><input type="radio" name="user_receipt" checked={taxreceipt} onChange={onTaxreceiptChange}/></label><div className="surecheck" style={{marginRight:"8px"}}>세금계산서</div>
                  <label><input type="radio" name="user_receipt" checked={cashreceipt} onChange={onCashreceiptChange}/></label><div className="surecheck" style={{marginRight:"8px"}}>현금영수증</div>
                  <label><input type="radio" name="user_receipt" checked={noreceipt} onChange={onNoreceiptChange}/></label><div className="surecheck">필요없음</div>
                   

                  </div>
                </div>
              

                <div>
                    <div className='titlearea'>
                      <div className='littletitle'>사업자등록번호</div>
                    </div>
                         <input
                             className="business"
                             type="text"
                             spellCheck="false"
                             name="user_businessnumber"
                             value={businessnumber}
                             onChange={(event) => {setBusinessnumber(event.target.value)}} />
                </div>

                <div>
                    <div className='titlearea'>
                      <div className='littletitle'>현금영수증 소득공제 번호</div>
                    </div>
                         <input
                             className="business"
                             type="text"
                             spellCheck="false"
                             name="user_cashnumber"
                             value={cashnumber}
                             onChange={(event) => {setCashnumber(event.target.value)}} />
                </div>
*/}



                <div>
                    <div className='titleareass' style={{marginTop:"70px"}}>
                      <div className='littletitle'>요청사항</div><div className='extras'>궁금하신 내용이 있으시다면 질문을 남겨주세요.</div>
                    </div>
                    
                         <textarea
                             className="note"
                             type="text"
                             spellCheck="false"
                             name="user_note"
                             maxLength={500}
                             value={note}
                             onChange={(event) => {setNote(event.target.value)}}
                             onKeyDown={(event) => {
                                 if (event.key === 'Enter') {
                                 event.preventDefault();
                                 setNote(note + '\n');
                                 }
                                 }}
                                 />
                    </div>

                 



                 <div>
                    <div className='titleareasss'>
                      <div className='checking'>


                      </div>
                      <label>
                    <input type="checkbox" checked={check} name="user_check" onChange={onCheckChange} />
                    </label>
                      <div className="sure"><a href="https://jungdari.com/suport/terms2.do" target="_blank" aria-label='move to family website'>개인정보 수집 및 이용</a></div>
                      <div>에 동의합니다.</div>
                    </div>
                </div>

            <button 
              className='nextbtnsa'
              disabled={!name || !pnumber || !email || !purchase || !targetitem || !targetarea || !lodging || !incomeuser || !check }
              type="submit" value="Send" onClick={() => {
                alert("제출되었습니다."); 
                setTimeout(() => setPage((currPage) => currPage + 1), 0);
              }}>제출하기</button>


              </div>
          )
            }
            else if (page === 1) {
                return(
                    <div>
                    <div>신청이 완료되었습니다!</div>
                    </div>
                )
            }
        }
              return (


                <div className='full' >
                    
                    <div className='notice'>
            <div className='noticecontents'>
            <div className='notictitle'>
                <img src={Bg} />
            </div>
            <div className='mo_notictitle'>
                <img src={Mobg} />
            </div>
            </div>
        </div>
        
        
        <div className="intro-wrap">
        <div>
        <img className="ds" src={a}/>
        </div>
        <div>
        <img className="ds" src={b}/>
        </div>
        </div>
        

        <div 
        className="board"
        >
                       <form className="abody" ref={form} onSubmit={onSubmit}>
                    {Pages()}
                    </form>
                </div>
                </div>
  );
}

export default Door;
