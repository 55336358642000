import React from 'react';
import AppRouter from "./Router";
import "./App.css"

function App() {

  return (
  <div className='totalview'>
  <div className='totalwrap'>
  <AppRouter  />
  </div>
  <footer>Copyright {new Date().getFullYear()} ⓒ BigpieC&T. All Rights Reserved</footer>
  </div>
  );
}

export default App;
