import React from "react";
import {
    HashRouter as Router,
    Route,
    Switch
  } from "react-router-dom";
  import Doors from "../routes/Doors";
  import Navigation from "../components/Navigation";
import Promotion from "../routes/Promotion";
  
  
  const AppRouter = () => {
    return (
      <Router>
                <Navigation/>
        <Switch>
            <>

              <Route exact path="/">
                <Doors />
              </Route>

            </>
      </Switch>
    </Router>
      );
    };
    export default AppRouter;