import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.svg"

const Navigation = () => (
  <nav>
    <div className='navigator'>
    <a href="https://jungdari.com/" rel="noopener noreferrer">
        <img src={Logo} alt="Logo" style={{ width: "150px" }} />
      </a>
    </div>
  </nav>
);
export default Navigation;