import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD8--2gshFxH1nD-QG9rklQifuI3akCoxE",
  authDomain: "market-research-f6905.firebaseapp.com",
  projectId: "market-research-f6905",
  storageBucket: "market-research-f6905.appspot.com",
  messagingSenderId: "555224394248",
  appId: "1:555224394248:web:86cc9447fcae7b38683fbd"
  };

firebase.initializeApp(firebaseConfig);

export const firebaseInstance = firebase;

export const dbService = firebase.firestore();

export const storageService = firebase.storage();