import { Link } from "react-router-dom";
import Logo from '../assets/logos.png';
import IMG from '../assets/testtttttt.jpg';
import PRO from '../assets/promo.jpg';

function Promotion() {
  return (
    <div>

        <div className='noticee'>
            <div className='noticecontentss'>
            <div className='notictitlee'>
                <img src={IMG} />
            </div>
            </div>
        </div>

        <div className="intro-wrapss">
        <div>
        <img className="ds" src={PRO}/>
        </div>
        </div>

        <div className="regi" style={{width:"100%", display:"flex"}}>
          <a href="https://jungdari.com/purchase/apply.do"> <button style={{border:"none", backgroundColor:"#6472ff", color:"#fff", padding:"1.2rem 2.4rem", fontSize:"1.8rem", borderRadius:"8px", cursor:"pointer"}}>신청하기</button></a>
        </div>

    </div>
  );
}

export default Promotion;
